<template>
  <v-bottom-navigation height="100px"
    v-model="value"
    background-color="#225856"
    dark
    fixed
    class="menuInferior"
  >
    <v-btn to="/catoraciones" value="catoraciones">
      <span class="textoMenuInferior">Oraciones</span>
        <img
          class="imagenesMenuInferior"
          :src="require('../assets/oraciones.svg')"
        />
    </v-btn>

    <v-btn to="/cataudios" value="audios">
      <span>Audios</span>
      <img
        class="imagenesMenuInferior"
        :src="require('../assets/audio.svg')"
      />
    </v-btn>

    <v-btn to="/recordatorios" value="recordatorios">
      <span>Recordatorios</span>
      <img
        class="imagenesMenuInferior"
        :src="require('../assets/recordatorio.svg')"
      />
    </v-btn>

    <v-btn to="/donativos" value="donativos">
      <span>Donativos</span>
      <img
        class="imagenesMenuInferior"
        :src="require('../assets/donaciones.svg')"
      />
    </v-btn>

  </v-bottom-navigation>
</template>
<style scoped>
.textoMenuInferior {
    top: calc(100% - 22px)!important;
}
.v-application .blue.darken-4 {
    background-color: #001993 !important;
    border-color: #001993 !important;
}
.menuInferior{
  padding-bottom:100px;
}
.imagenesMenuInferior{
  margin-bottom: 10px; 
  max-width: 55px;
  max-height: 50px;
}
</style>

<script>
  export default {
    name: 'BottomMenu',
    data: () => ({
        value: '',
    }),
    mounted: function(){
        this.value = this.$store.getters.seccion;
    }
  }
</script>
