import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: {
        login: false,
        token: '',
        id: 0,
        name: '',
        email: '',
        verificado: false,
        created_at: '',
        updated_at: '',
    },
    msg: {
        show: false,
        color: 'red',
        timeout: 5000,
        message: '',
    },
    seccion: 'inicio'
  },

  getters: {
      drawer: state => state.drawer,
      login: state => state.login,
      msg: state  => state.msg,
      seccion: state => state.seccion,
  },
  mutations: {
      logout: function(state){
          state.login.login = false;
          state.login.token = '';
          state.login.id = 0;
          state.login.name = '';
          state.login.email = '';
          state.login.verificado = '';
          state.login.created_at = '';
          state.login.updated_at = '';
          localStorage.removeItem('ora_token');
          localStorage.removeItem('ora_id');
          localStorage.removeItem('ora_name');
          localStorage.removeItem('ora_email');
          localStorage.removeItem('ora_verificado');
          localStorage.removeItem('ora_created_at');
          localStorage.removeItem('ora_updated_at');
          router.push('/login');
      },
      loginSend: function(state, datos){
          state.login.login = true;
          state.login.token = datos.token;
          state.login.id = datos.id;
          state.login.name = datos.name;
          state.login.email = datos.email;
          state.login.verificado = datos.verificado;
          state.login.created_at = datos.created_at;
          state.login.updated_at = datos.updated_at;
          localStorage.setItem('ora_token', datos.token);
          localStorage.setItem('ora_id', datos.id);
          localStorage.setItem('ora_name', datos.name);
          localStorage.setItem('ora_email', datos.email);
          localStorage.setItem('ora_verificado', datos.verificado);
          localStorage.setItem('ora_created_at', datos.created_at);
          localStorage.setItem('ora_updated_at', datos.updated_at);
          router.push('/');
      },
      loginSet: function(state, datos){
          state.login.login = true;
          state.login.token = datos.token;
          state.login.id = datos.id;
          state.login.name = datos.name;
          state.login.email = datos.email;
          state.login.verificado = datos.verificado;
          state.login.created_at = datos.created_at;
          state.login.updated_at = datos.updated_at;
          //router.push('/');
      },
      msgMuestra: function(state, vars){
          state.msg.color = vars.color;
          state.msg.timeout = vars.timeout;
          state.msg.message = vars.message;
          state.msg.show = true;
      },
      msgOculta: function(state){
          state.msg.color = 'red';
          state.msg.timeout = 5000;
          state.msg.message = '';
          state.msg.show = false;
      },
      seccionSet: function(state, seccion){
          state.seccion = seccion;
      }
  },
  actions: {
  },
  modules: {
  }
})
