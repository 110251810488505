<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      color="#CFC8AC "
    >
      <v-list color="#225856" dark>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ $store.getters.login.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{
              $store.getters.login.email
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item
          link
          v-for="(item, index) in items"
          :key="index"
          :to="item.action"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#225856"
      dark
      height="100px"
    >
      <v-app-bar-nav-icon
        class="iconoMenu"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="tituloMenu">María Madre</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon large class="me-4" to="/">
        <v-avatar size="64px" item>
          <v-img
            :src="require('../assets/mini.jpg')"
            alt="oraciones"
            class="imgLogoMenu"
          ></v-img
        ></v-avatar>
      </v-btn>
    </v-app-bar>
  </div>
</template>
<style scoped>
.v-application .blue.darken-4 {
  background-color: #001993 !important;
  border-color: #001993 !important;
}

.iconoMenu i {
  font-size: 40px !important;
}

.tituloMenu {
  font-size: 1.4rem;
}

.v-list-item--dense .v-list-item__icon,
.v-list--dense .v-list-item .v-list-item__icon {
  margin-top: 11px;
}

.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-size: 1.3rem;
  font-weight: 400;
}

.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  line-height: 3rem;
}

.imgLogoMenu {
  border: 2px solid #cfc8ac;
}
</style>
<script>
export default {
  name: "NavDrawer",
  data: () => ({
    drawer: null,
    items: [
      { icon: "mdi-home", text: "Inicio", action: "/" },
      { icon: "mdi-account", text: "Mi Cuenta", action: "/mi-cuenta" },
      {
        icon: "mdi-book-open-page-variant",
        text: "Oraciones",
        action: "/catoraciones",
      },
      { icon: "mdi-music-circle", text: "Audios", action: "/cataudios" },
      { icon: "mdi-alarm", text: "Recordatorios", action: "/recordatorios" },
      { icon: "mdi-cash-multiple", text: "Donativos", action: "/donativos" },
      { icon: "mdi-logout", text: "Salir", action: "/logout" },
    ],
  }),
};
</script>
