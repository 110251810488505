import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

if (process.env.NODE_ENV == "production") {
  Vue.prototype.$url = "https://oraciones2.pax-support.com/api/";
} else {
  Vue.prototype.$url = "http://localhost:8000/api/";
}

// Validation rules
Vue.prototype.$rules = {
  required: [(v) => !!v || "Este campo es requerido"],
  password: [
    (v) => !!v || "Este campo es requerido",
    (v) =>
      (v && /.{8,}/.test(v)) ||
      "La contraseña debe de tener al menos 8 caracteres",
  ],
  requiredEmail: [
    (v) => !!v || "Este campo es requerido",
    (v) =>
      (v && /.+@.+\..{2,}/.test(v)) || "Ingresa una dirección de correo válida",
  ],
  required_phone: [
    (v) => !!v || "Este campo es requerido",
    (v) => (v && /^\d{10}$/.test(v)) || "Este campo sólo permite 10 dígitos",
  ],
  phone: [
    (v) => !v || /^\d{10}$/.test(v) || "Este campo sólo permite 10 dígitos",
  ],
  max50: [
    (v) => !!v || "Es requerido",
    (v) => (v.length <= 50 && v.length >= 1) || "Máximo 50 caracteres",
  ],
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
