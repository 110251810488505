<template>
  <v-container class="pa-0 ma-0">
    <v-row class="text-center">
      <v-col cols="12">
        <p class="text-h5 pa-0 ma-0 text-center">Asociación de Fieles</p>
        <p class="text-subtitle-1 text-center">
          "María Madre Guardiana de la Fé"
        </p>
        <!-- <v-img :src="require('../assets/logo_oraciones.png')" class="mb-3" contain height="200" /> -->
      </v-col>

      <template v-if="favs.oraciones.length > 0">
        <v-col class="mb-4">
          <h4 class="pb-2">Oraciones</h4>
          <v-row>
            <v-col
              v-for="f in favs.oraciones"
              :key="f.tipo + '-' + f.id"
              class="d-flex child-flex"
              cols="4"
            >
              <v-img
                :src="$url + '../../storage/' + f.image"
                :lazy-src="$url + '../../storage/' + f.image"
                aspect-ratio="1"
                class="grey lighten-2 imageFav"
                @click="detalles(f.id, f.tipo)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
          <h4 class="pt-4 pb-2">Audios</h4>
          <v-row>
            <v-col
              v-for="f in favs.audios"
              :key="f.tipo + '-' + f.id"
              class="d-flex child-flex"
              cols="4"
            >
              <v-img
                :src="$url + '../../storage/' + f.image"
                :lazy-src="$url + '../../storage/' + f.image"
                aspect-ratio="1"
                class="grey lighten-2 imageFav"
                @click="detalles(f.id, f.tipo)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>
<style scoped>
.subheading {
  font-size: 35px;
}

.imageFav {
  border-radius: 15px;
}
</style>
<script>
export default {
  name: "HelloWorld",

  data: () => ({
    favs: [],
  }),
  methods: {
    detalles(id, tipo) {
      if (tipo == "oracion") {
        this.$router.push({
          name: "oracion",
          params: {
            oracion: id,
            category: "Favoritos",
            items: this.favs.oraciones,
            regresar: "home",
          },
        });
      } else {
        this.$router.push({
          name: "audio",
          params: {
            audio: id,
            category: "Favoritos",
            items: this.favs.audios,
            regresar: "home",
          },
        });
      }
    },
    loadData() {
      var este = this;
      este.loading = true;
      var data = {
        id: this.$store.getters.login.id,
        token: this.$store.getters.login.token,
      };
      this.$http
        .post(this.$url + "favoritos", data)
        .then(function (response) {
          window.console.log(response);
          este.favs = response.data;
          este.loading = false;
        })
        .catch(function (err) {
          este.loading = false;
          if (
            typeof err.response !== "undefined" &&
            err.response.status == 401
          ) {
            este.$store.commit("logout");
          }
          var errMsg;
          if (typeof err.response !== "undefined") {
            errMsg = err.response.data.msg;
          } else {
            errMsg = "Ocurrió un error. por favor intenta de nuevo.";
          }
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: errMsg,
          };
          este.$store.commit("msgMuestra", mensaje);
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
