<template>
  <hello-world />
</template>

<script>
  import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'HomeView',

    components: {
      HelloWorld,
    },
    mounted: function(){
        this.$store.commit('seccionSet', 'home');
    }
  }
</script>
