<template>
  <v-app
    id="oraciones"
    :style="!$store.getters.login.login ? 'background: #225856;' : ''"
  >
    <msg-component />
    <nav-drawer v-if="$store.getters.login.login"></nav-drawer>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <bottom-menu v-if="$store.getters.login.login"></bottom-menu>
  </v-app>
</template>
<style>
.container {
  padding: 40px 30px 170px !important;
}
.boton-flotante {
  bottom: 70px !important;
}
.capitalize {
  text-transform: capitalize;
}
.sinPadding {
  padding: 0;
}
.v-icon.v-icon {
  color: #225856;
}
#oraciones {
  /* background-image:url(@/assets/back2.jpg) !important; */
  background-image: url(@/assets/virgen.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
.v-item-group.v-bottom-navigation .v-btn {
  flex: 1 0 auto !important;
}
</style>
<script>
import NavDrawer from "./components/NavDrawer";
import MsgComponent from "./components/MsgComponent";
import BottomMenu from "./components/BottomMenu";

export default {
  name: "App",

  data: () => ({
    dialog: false,
  }),
  components: {
    NavDrawer,
    MsgComponent,
    BottomMenu,
  },
  mounted() {
    let este = this;
    if (localStorage.getItem("ora_id") !== null) {
      var datos = {
        token: localStorage.getItem("ora_token"),
        id: localStorage.getItem("ora_id"),
        name: localStorage.getItem("ora_name"),
        email: localStorage.getItem("ora_email"),
        verificado: localStorage.getItem("ora_verificado"),
        created_at: localStorage.getItem("ora_created_at"),
        updated_at: localStorage.getItem("ora_updated_at"),
      };
      este.$store.commit("loginSet", datos);
    }
  },
};
</script>
