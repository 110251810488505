import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {requiresAuth: true}
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/LogoutView.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/mi-cuenta',
    name: 'micuenta',
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/catoraciones',
    name: 'catoraciones',
    component: () => import(/* webpackChunkName: "catoraciones" */ '../views/CatoracionesView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/oraciones',
    name: 'oraciones',
    component: () => import(/* webpackChunkName: "oraciones" */ '../views/OracionesView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/oracion',
    name: 'oracion',
    component: () => import(/* webpackChunkName: "oracion" */ '../views/OracionView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/cataudios',
    name: 'cataudios',
    component: () => import(/* webpackChunkName: "catoraciones" */ '../views/CataudiosView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/audios',
    name: 'audios',
    component: () => import(/* webpackChunkName: "audios" */ '../views/AudiosView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/audio',
    name: 'audio',
    component: () => import(/* webpackChunkName: "oracion" */ '../views/AudioView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/recordatorios',
    name: 'recordatorios',
    component: () => import(/* webpackChunkName: "recordatorios" */ '../views/RecordatoriosView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/donativos',
    name: 'donativos',
    component: () => import(/* webpackChunkName: "donativos" */ '../views/DonativosView.vue'),
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    window.console.log(router);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //if (!router.app.$token) {
        if (!store.getters.login.login) {
            window.console.log('Requiere auth');
            next('/login');
            return;
        }
    }
    next();
});
export default router
